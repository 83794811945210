<template>
    <div class="order-revenue">
        <div class="top-nav">
            <span :class="{'ac': Tab == 0}" @click="changeTab(0)">全部</span>
            <span :class="{'ac': Tab == 1}" @click="changeTab(1)">待入账</span>
            <span :class="{'ac': Tab == 2}" @click="changeTab(2)">已到账</span>
            <span :class="{'ac': Tab == 3}" @click="changeTab(3)">有扣减</span>
        </div>
        <div class="list">
            <team-order-list :list="list" :isLoad="isLoad" :loadLastText="loadMore" @getMore="getProfitOrderByTeam">
            </team-order-list>
        </div>
    </div>
</template>

<script>
    import {
        getProfitOrderByUser,
        getProfitOrderByTeam
    } from "@/http/api";
    import TeamOrderList from "@/components/teamOrder/TeamOrderList";
    export default {
        name: "",
        components: {
            TeamOrderList
        },
        props: {},
        data() {
            return {
                userToken: "",

                userInfo: {},
                list: [],
                isLoad: false,
                loadMore: true,
                pages: 1,
                Tab: 0,
                types: 0
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.getProfitOrderByTeam();
        },
        methods: {
            // 获取团队收益列表
            async getProfitOrderByTeam() {
                let res;
                if (this.types == "1") {
                    res = await getProfitOrderByUser({
                        data: {
                            user_token: this.userToken,
                            profit_types: this.Tab,
                            pages: this.pages
                        }
                    });
                }
                if (this.types == "2") {
                    res = await getProfitOrderByTeam({
                        data: {
                            user_token: this.userToken,
                            profit_types: this.Tab,
                            pages: this.pages
                        }
                    });
                }
                this.isLoad = true;
                if (res.code == "200") {
                    this.list = this.list.concat(res.data);
                    if (res.data.length < res.numPage) {
                        this.loadMore = false;
                    } else {
                        this.pages++;
                        this.loadMore = true;
                    }
                }
            },
            // 切换
            changeTab(tab) {
                this.Tab = tab;
                this.list = [];
                this.isLoad = false;
                this.loadMore = true;
                this.pages = 1;
                this.getProfitOrderByTeam();
            }
        },

        computed: {},
        watch: {},
        created() {
            this.types = this.$route.query.types
            if (this.types == "1") {
                document.title = "我的订单收益";
            } else if (this.types == "2") {
                document.title = "我的团队收益";
            }
        }
    };
</script>

<style scoped lang="scss">
    .order-revenue {
        background: #f6f6f6;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .top-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            height: 1.04rem;
            font-size: 0.3rem;
            box-shadow: #eee 0.2rem 0 0.2rem;

            span {
                color: #434343;
                border-bottom: 1px solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25vw;
                height: 50px;
            }

            .ac {
                color: #ff5400;
                border-bottom: 1px solid #ff5400;
            }
        }

        .list {
            height: calc(100vh - 1.04rem);
            overflow-y: auto;
            padding-top: 0.3rem;
        }
    } 
</style>
