<template>
    <div class="grid">
        <div class="grid-hd">
            <div class="user-info">
                <img :src="item.headimgurl" />
                <span>{{ item.nickname }}</span>
            </div>
            <p class="order-status">{{ orderStatus.statusText }}</p>
        </div>
        <div class="goods-info">
            <img :src="item.goods_image" />
            <p class="goods-name">{{ item.goods_name }}</p>
            <div class="goods-bot">
                <div class="goods-price">￥{{ item.goods_price }}</div>
                <div class="goods-num">×1</div>
            </div>
        </div>
        <div class="order-info" v-show="isShow">
            <div class="flex-between">
                <p>购买人：{{ item.address_contacts | noPassByName }}</p>
                <p>实付：￥{{ item.order_amount }}</p>
            </div>
            <p>手机号：{{ item.address_phone | noPassByMobile }}</p>
            <p>下单时间：{{ item.create_time }}</p>
            <p>订单编号：{{ item.order_no }}</p>
            <p v-if="item.goods_types == 3">过期时间：{{ item.expire_time }}</p>
            <p class="red" v-if="item.wallet_status > 0">收入详情：饭佣{{ item.amount }}元</p>
            <p v-if="item.wallet_status == -1">扣减说明：因售后问题造成饭佣扣减</p>
        </div>
        <div class="switch" @click="changeDown">
            {{ downText }}
            <img src="@/assets/images/qiehuan.png" />
        </div>
        <div class="bot-row">
            <div class="bot-left">
                {{ orderStatus.moneyT }}：
                <span v-if="item.wallet_status > 0">{{ item.amount }}元</span>
                <span v-if="item.wallet_status == -1">0元</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="tag">{{ orderType }}</span>
            </div>
            <div :class="['bot-right',{'visited':item.is_remind==1}]"
                v-if="item.goods_types == 3 && item.wallet_status == 1" @click="remindForCards">提醒使用</div>
        </div>
    </div>
</template>

<script>
    import {
        remindForCards
    } from "@/http/api";
    export default {
        props: {
            item: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        data() {
            return {
                userToken: "",
                downText: "展开",
                isShow: false
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
        },
        methods: {
            changeDown() {
                this.isShow = !this.isShow;
                if (this.isShow) {
                    this.downText = "折叠";
                } else {
                    this.downText = "展开";
                }
            },
            // 提醒使用
            async remindForCards() {
                if (this.item.is_remind == 1) {
                    this.$toast("已经提醒过啦");
                } else {
                    let item = this.item;
                    const res = await remindForCards({
                        data: {
                            user_token: this.userToken,
                            wallet_id: item.wallet_id,
                            cards_user_id: item.cards_user_id,
                            // order_cards_id: item.order_cards_id,
                            // order_id: item.order_id,
                            order_no: item.order_no
                        }
                    });
                    console.log(res);
                    if (res.code == 200) {
                        this.$toast("提醒成功");
                        this.item.is_remind = 1;
                    }
                }
            }
        },

        computed: {
            orderStatus() {
                let num = this.item.wallet_status;
                if (num) {
                    switch (num) {
                        case -1:
                            return {
                                statusText: "饭佣扣减",
                                moneyT: "贡献"
                            };
                        case 0:
                            return {
                                statusText: "冻结中",
                                moneyT: "预计"
                            };
                        case 1:
                            return {
                                statusText: "待入账",
                                moneyT: "预计"
                            };
                        case 2:
                            return {
                                statusText: "已到账",
                                moneyT: "贡献"
                            };
                        default:
                            return {
                                statusText: "未知",
                                moneyT: "未知"
                            };
                    }
                }
            },
            orderType() {
                let goodsTypes = this.item.goods_types;
                switch (goodsTypes) {
                    case 3:
                        return "卡券订单";
                    case 1:
                        return "实物订单";
                    default:
                        return "未知订单";
                }
            }
        },
        filters: {}
    };
</script>

<style lang='scss' scoped>
    .flex-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .grid {
        width: 94vw;
        margin: 0 3vw 4vw;
        background: #fff;
        overflow: hidden;

        .grid-hd {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            padding: 0 10px;
            font-size: 0.3rem;

            .user-info {
                display: flex;
                align-items: center;
            }

            img {
                width: 8vw;
                height: 8vw;
                margin-right: 15px;
                border-radius: 50%;
            }
        }

        .goods-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            margin-bottom: 15px;

            img {
                width: 1.15rem;
                height: 0.93rem;
        
   }

            .goods-name {
                width: 44.8vw;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 0.26rem;
            }

            .goods-bot {
                width: 20vw;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                height: 12.4vw;
                font-size: 0.3rem;

                .goods-price {
                    color: #000000;
                }

                .goods-num {
                    color: #adadad;
                }
            }
        }

        .order-info {
            font-size: 0.26rem;
            line-height: 1.8;
            padding: 0 10px;

            .red {
                color: #e40011;
            }
        }

        .switch {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 10px;
            border-bottom: 1px solid #ebebeb;
            font-size: 0.24rem;
            color: #adadad;

            img {
                width: 10px;
                margin-left: 5px;
            }
        }

        .bot-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            height: 50px;
            font-size: 0.24rem;

            span {
                color: #ff6b17;
                font-size: 0.3rem;
            }

            .tag {
                font-size: 0.24rem;
                margin-left: 0.3rem;
                color: #333;
            }

            .bot-right {
                background-color: #ff6b17;
                border-radius: 0.1rem;
                text-align: center;
                width: 1.3rem;
                line-height: 0.55rem;
                color: #fff;
                font-size: 0.24rem;

                &.visited {
                    background-color: #dfdfdf;
                }
            }
        }
    }
</style>
