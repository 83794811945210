<template>
  <div class="list-box" v-if="isLoad">
    <template v-if="list.length > 0">
      <team-order-item v-for="(item, index) in list" :key="index" :item='item'></team-order-item>
      <load-more :loadLastText='loadLastText' @getMore='getMore'></load-more>
    </template>
    <no-data v-else content='这里没有数据'></no-data>
  </div>
</template>

<script>
import TeamOrderItem from './TeamOrderItem'
export default {
  components: {
    TeamOrderItem
  },
  props: {
    listType: {
      type: String,
      default() {
        return ''
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    loadLastText: {
      type: Boolean,
      default() {
        return true
      }
    },
    isLoad: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    getMore() {
      this.$emit('getMore')
    }
  },
  mounted() {
    // console.log(this.list)
  }
}
</script>

<style>

</style>